import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'
import Helmet from 'react-helmet'

import { ArrowDown } from './icons'
import scrollIt from '../utils/scroll'

class PageCover extends Component {

  state = {
    fixed: null,
    opacity: 1,
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll)
    this.onScroll()
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll)
  }

  onScroll = () => {
    let currentScroll = document.documentElement.scrollTop || document.body.scrollTop
    let { fixed } = this.state

    if (currentScroll >= window.innerHeight * (2/3)) {
      fixed && this.setState({ fixed: false })
    } else {
      !fixed && this.setState({ fixed: true })
    }
  }

  _renderContent() {
    return (
      <div className='page-cover__inner' style={{ backgroundColor: this.props.backgroundColor || '#fff' }}>
        <Fade bottom distance='40px'>
          <div className='page-cover__center'>
            <div className="page-cover__content" dangerouslySetInnerHTML={{ __html: this.props.content }} />
          </div>
        </Fade>
        <ArrowDown className="arrow" colour="#737373" onClick={() => {
          const $elm = document.querySelector('.page-cover')
          const height = ($elm.offsetTop + $elm.scrollHeight)
          scrollIt(height, 300, 'easeOutQuad')
        }} />
      </div>
    )
  }

  render() {
    let { fixed } = this.state

    let bodyClass = fixed ? 'page-cover__fixed' : ''
    if (typeof window !== 'undefined' && window.location.pathname === '/') {
      bodyClass += ' home'
    }

    let $content = typeof document !== 'undefined' && document.querySelector('.page-cover__under')
    let $footer = typeof document !== 'undefined' && document.querySelector('.footer')
    let contentHeight = $content && $footer ? $content.scrollHeight + $footer.scrollHeight : 0
    let bodyHeight = contentHeight + 'px'

    const getBodyStyleAttribute = (bodyHeight) => {
      if (typeof window !== 'undefined') {
        return [
          bodyHeight && `padding-bottom: ${fixed ? bodyHeight : 0};`,
        ].filter(s => s).join('')
      }
      return { paddingBottom: fixed ? bodyHeight : 0 }
    }

    return (
      <>
        <Helmet>
          <body className={bodyClass} style={getBodyStyleAttribute(bodyHeight)} />
        </Helmet>
        <section className="page-cover" style={{ opacity: this.state.opacity }}>
          { this.props.content && this._renderContent() }
          { this.props.children }
        </section>
      </>
    )
  }
}

export default PageCover
