import React, { Component } from 'react'
import { graphql } from 'gatsby'
import moment from 'moment'
import he from 'he'

import SEO from '../components/seo'
import PageCover from '../components/page-cover'
import BlogCard from '../components/cards/blog-card'

class CareersTemplate extends Component {

  lastScroll = 0

  state = {
    length: 12,
  }

  componentDidMount() {
    window.addEventListener('scroll', this.scrollDetect)
    this.await = true
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollDetect)
  }

  scrollDetect = () => {
    let currentScroll = document.documentElement.scrollTop || document.body.scrollTop
    let { length } = this.state
    let posts = this.props.data.allWordpressWpJob.edges

    if (this.await && posts.length > length && currentScroll / (document.querySelector('.subscribe').offsetTop - window.innerHeight) > 0.9) {
      this.await = false
      setTimeout(() => this.await = true, 200)
      this.setState({ length: length + 3 })
    }
    this.lastScroll = currentScroll
  }

  render() {
    const page = this.props.data.wordpressPage
    let title = 'Blog'
    if (this.props.pageContext.category) {
      title = `${this.props.pageContext.category} Blog`
    }

    let { length } = this.state
    const { data } = this.props
    let posts = {};
    if(data.allWordpressWpJob) {
        posts = data.allWordpressWpJob.edges;
        const allShown = posts.length <= length
        let count = 0
        if (allShown) {
            length = posts.length
        }
        const loadMore = (event) => {
            event.preventDefault()
            this.setState({length: this.state.length + 8})
        }


        return (
            <>
                <SEO title={title}/>
                {this.props.pageContext.skip === 0 && page.acf.page_cover && <PageCover content={page.acf.page_cover}/>}
                <div className='page-cover__under'>
                    <section className="blogs blogs--component">
                        <div className="blogs__inner blogs__padding container">
                            <div className="blogs__items">
                                {posts && posts.map((el, i) => {
                                    let props = {
                                        key: i,
                                        image: el.node.acf.featured_image && el.node.acf.featured_image.localFile && el.node.acf.featured_image.localFile.childImageSharp && el.node.acf.featured_image.localFile.childImageSharp.fixed.src,
                                        title: he.decode(el.node.title),
                                        link: `/job/${el.node.slug}`,
                                        author: el.node.acf.author_name ? el.node.acf.author_name : el.node.author,
                                        date: moment(el.node.date).format("MMM, YYYY")
                                    }
                                    if (++count > length)
                                        return false
                                    return (<BlogCard {...props} />)
                                })}
                            </div>
                            {posts.length === 0 && <p className="blogs__more">Check back soon...</p>}
                            {!allShown &&
                            <a className="blogs__more" href="#more" onClick={loadMore}>Load more...</a>
                            }
                        </div>
                    </section>
                </div>
            </>
        )
    }
    else
    {
        return (
            <>
                <SEO title={title}/>
                <div className='page-cover__under'>
                    <section className="blogs blogs--component">
                        <div className="blogs__inner blogs__padding container">
                        </div>
                    </section>
                </div>
            </>
        )
    }
  }
}

export default CareersTemplate

export const careersQuery = graphql`
  query ($skip: Int = 0, $limit: Int = 99999) {
    allWordpressWpJob(limit: $limit, skip: $skip, filter:{ wordpress_id: { ne: 3836 }}, sort: {fields: [date], order: DESC}) {
      edges {
        node {
          wordpress_id
          title
          date
          slug
          acf {
            featured_image {
              localFile {
                childImageSharp {
                  fixed(width: 1200) {
                    src
                  }
                }
              }
            }
          }
        }
      }
    }
    wordpressPage(wordpress_id: { eq: 3845 }) {
      title
      slug
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
      acf {
        page_cover
      }
    }
  }
`
