import React, { Component } from 'react'
import { Link } from 'gatsby'
import Fade from 'react-reveal/Fade'

class BlogCard extends Component {
  render() {
    let { image, title, link, author, date } = this.props

		var parts = link.split('/');
		var lastSegment = parts.pop() || parts.pop();
		//link = lastSegment

    return (
      <Fade bottom distance='40px'>
        <div className="card card--blog">
          <Link to={link}>
            <figure>
              <img src={image} alt={title} />
            </figure>
            <span>{author} | {date}</span>
            <h4>{title}</h4>
          </Link>
        </div>
      </Fade>
    )
  }
}


export default BlogCard
